<template>
    <main>
      <!-- Modal -->
      <div class="modal fade" id="modalNewPartograma" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Iniciar Partograma</h5>
              <button type="button" class="close" @click="cerrar()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="card shadow-none border-0">
                <div class="card-body">
                  <div class="form-group ">
                    <label class="form-label">Fecha<span class="text-danger">*</span></label>
                    <input type="date" class="form-control form-control-sm" v-model="fecha"
                    :class="{'is-invalid':$v.fecha.$error && $v.fecha.$dirty}">
                  </div>
                  <div class="form-group ">
                    <label class="form-label">No. Historia Clinica</label>
                    <input type="number" class="form-control form-control-sm" v-model="id_historia">
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-success btn-sm" @click="guardar();"><i
                  class="fa fa-save fa-fw"></i>Guardar</button>
              <button type="button" class="btn btn-light btn-sm" @click="cerrar()"><i
                  class="fa fa-times-circle fa-fw"></i>Cerrar</button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-header">
        <div class="row">
          <div class="col-6 ">
            Partograma
          </div>
          <div class="col-6 d-flex justify-content-end" v-if="_can('card.partograma')">
            <button v-if="ultimo_partograma.activo !== 'S'" class="btn btn-primary btn-xs" type="button" data-toggle="modal"
              data-target="#modalNewPartograma">
              Nuevo &nbsp;<i class="fas fa-plus"></i>
            </button>
            <div v-else>
              <button class="btn btn-success btn-xs mx-2" type="button" @click="finalizar">Finalizar</button>
              <button class="btn btn-info btn-xs" type="button" @click="editar">Ver</button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <span class="d-block small">Ultimo Partograma :
          <span v-if="Object.keys(ultimo_partograma).length">{{ ultimo_partograma.fecha }}
            <router-link class="btn btn-secondary btn-xs" :to="{name:'partograma',params:{id:ultimo_partograma.id}}">Iniciar registro</router-link>ß
          </span>
          <span v-else class="text-danger">Sin datos...</span>
        </span>
      </div>
    </main>
  </template>
  <script>
  
  import partogramaService from '../../../../services/partogramaService';
  import $ from 'jquery';
  import Swal from "sweetalert2";
  import {required} from "vuelidate/lib/validators";
  export default {
    props: ['idUsuario', 'usuario'],
    data() {
      return {
        partograma: {},
        fecha: '',
        ultimo_partograma: {},
        id_usuario: '',
        type:'store',
        id_historia:''
      }
    },
    validations(){
      return {
        fecha:{required},
      }
    },
    methods: {
      async cargarPartograma() {
        const response = partogramaService.showById(this.idUsuario);
        this.partograma = response.data;
      },
      async cargarUltimoPartograma(id) {
        const response = await partogramaService.showByLast(id);
        this.ultimo_partograma = response.data;
      },
      async guardar() {
        try {
  
          this.$v.$touch();
          if(this.$v.$invalid) return;
  
          let partograma = {
            fecha: this.fecha,
            id_usuario: this.idUsuario,
            id_historia:this.id_historia
          };
  
          this.LoaderSpinnerShow();
          if(this.type==='store'){
            await partogramaService.store(partograma)
          }else{
            await partogramaService.update(partograma)
          }
          
          this.LoaderSpinnerHide();
          $(`#modalNewPartograma`).modal('hide');
          this.cargarUltimoPartograma(this.idUsuario);
          Swal.fire('Datos guardados con exito', '', 'success');
        } catch (error) {
          console.error(error);
          this.LoaderSpinnerHide();
          Swal.fire('Ha ocurrido un error al procesar la solicitud', '', 'error');
        }
  
  
      },
      async finalizar() {
        const result = await Swal.fire({
          title: "¿Está seguro de finalizar el partograma ?",
          text: "Al finalizar el partograma no se podra continuar el control prenatal",
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: 'Sí, finalizar',
          cancelButtonText: 'No',
          icon: "warning",
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            try {
              await partogramaService.close(this.ultimo_partograma.id);
            } catch (e) {
              console.error(e);
              Swal.showValidationMessage('ha ocurrido un error al procesar la solicitud');
            }
  
          },
        });
  
        if (result.isConfirmed) {
          Swal.fire('Exíto', 'partograma Finalizado con Exíto', 'success');
          this.cargarUltimoPartograma(this.idUsuario);
        }
      },
      cerrar(){
        $('#modalNewPartograma').modal('hide');
        this.$refs.antecedenteObstetrico.limpiar();
      },
      editar(){
        $('#modalNewPartograma').modal('show');
        this.fecha=this.ultimo_partograma.fecha;
        this.type='update';
      }
    },
    created() {
      this.cargarUltimoPartograma(this.idUsuario);
    },
    watch: {
      idUsuario: function () {
        this.cargarUltimoPartograma(this.idUsuario);
      }
    },
  }
  </script>